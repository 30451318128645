import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    listRichMenu(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/list_richmenu', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getRichMenu(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_richmenu', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRichMenu(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/add_richmenu', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editRichMenu(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/edit_richmenu/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRichMenu(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/delete_richmenu/${params.id}`, params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultRichMenu(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_default_richmenu', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createRichMenu(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/create_richmenu/${params.id}`, params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageRichMenu(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/upload_image_richmenu/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getRichMenuLink(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_richmenu_link', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    linkRichMenu(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/link_richmenu', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    defaultRichMenu(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/default_richmenu/${params.id}`, params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
