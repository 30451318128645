import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'

export default function tableRichMenu() {
  const toast = useToast()
  const refRichMenuTable = ref(null)
  const lineOa = computed(() => store.state.lineapi.lineOa)

  const msg = {
    errorfetch: t2('Error fetching {module} list', { module: t('Rich Menu') }),
    name: `${t('Name')} » ${t('Rich Menu')} ${t('ID')} » ${t('Type')}`,
    use: `${t('Line User')} »  ${t('Use')} »  ${t('Date')} »  ${t('Time')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const richMenuColumns = [
    { key: 'name', sortable: false, label: msg.name },
    { key: 'use', sortable: false, label: msg.use },
    { key: 'actions', label: msg.actions },
  ]

  const richMenuColumnStyle = key => {
    if (key === 'name') return 'width: 40%'
    if (key === 'detail') return 'width: 40%'
    if (key === 'actions') return 'width: 20%'
    return ''
  }

  const richMenuPerPage = ref(10)
  const richMenuTotal = ref(0)
  const richMenuCurrentPage = ref(1)
  const richMenuPerPageOptions = [2, 5, 10, 25, 50, 100]
  const richMenuSearchQuery = ref('')
  const richMenuSortBy = ref('name')
  const richMenuIsSortDirDesc = ref(true)
  const richMenuLocale = ref('th')

  const richMenuDataMeta = computed(() => {
    const localItemsCount = refRichMenuTable.value ? refRichMenuTable.value.localItems.length : 0
    return {
      from: richMenuPerPage.value * (richMenuCurrentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: richMenuPerPage.value * (richMenuCurrentPage.value - 1) + localItemsCount,
      of: richMenuTotal.value,
    }
  })

  const refetchRichMenuData = () => {
    refRichMenuTable.value.refresh()
  }

  watch([richMenuCurrentPage, richMenuPerPage, richMenuSearchQuery, richMenuLocale], () => {
    refetchRichMenuData()
  })

  const getListRichMenu = (ctx, callback) => {
    const { id } = JSON.parse(lineOa.value)
    const params = {
      q: richMenuSearchQuery.value,
      perPage: richMenuPerPage.value,
      page: richMenuCurrentPage.value,
      sortBy: richMenuSortBy.value,
      sortDesc: richMenuIsSortDirDesc.value,
      language: richMenuLocale.value,
      lineOaId: id,
    }
    store
      .dispatch('richmenu-store/listRichMenu', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        richMenuTotal.value = total
        localStorage.setItem('filterRichMenu', JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msg.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  return {
    getListRichMenu,
    richMenuColumns,
    richMenuColumnStyle,
    richMenuPerPage,
    richMenuCurrentPage,
    richMenuTotal,
    richMenuDataMeta,
    richMenuPerPageOptions,
    richMenuSearchQuery,
    richMenuSortBy,
    richMenuIsSortDirDesc,
    richMenuLocale,

    refRichMenuTable,
    refetchRichMenuData,
  }
}
