import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    listRichMenuTemplate(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/list_richmenu_template', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getRichMenuTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_richmenu_template', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRichMenuTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/add_richmenu_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editRichMenuTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/edit_richmenu_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRichMenuTemplate(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/delete_richmenu_template/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultRichMenuTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_default_richmenu_template', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageRichMenuTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/upload_image_richmenu_template/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
